import { styled } from "styled-components";
import hexagon from "../assets/hexagon.png";

const Bubble = styled.div`
  display: inline-block;
  line-height: 120px;
  background: url(${hexagon}) no-repeat;
  background-size: contain;
  color: #ffffff;
  width: 120px;
  height: 120px;
  position: relative;
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }
  transform: ${(props) => (props.animate ? `scale(1.1)` : `scale(1)`)};
  @media screen and (width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const SkillBubble = ({ name, animate }) => {
  return <Bubble animate={animate}>{name}</Bubble>;
};
