import { styled } from "styled-components";
import backgroundImage from "../assets/background-1.jpg";
import { forwardRef } from "react";
import { Desk } from "../components/desk";

const Bg = styled.div`
  background-color: rgb(42 98 136);
  min-height: 100vh;
  width: 100%;
  background-image: url(${backgroundImage});
  background-size: cover;
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.44;
    background: #22413d;
  }
  display: flex;
  align-items: center;
`;
const Wrapper = styled.div`
  max-width: 1080px;
  margin: auto;
  display: flex;
  flex-direction: column-reverse;
  width: 90%;

  @media screen and (min-width: 1024px) {
    min-width: 960px;
    flex-direction: row;
    align-items: center;
  }
`;
const Headline1 = styled.h1`
  position: relative;
  font-family: "Library 3 am soft";
  color: #61dafb;
  font-size: 30px;
  font-weight: normal;
  @media screen and (min-width: 1024px) {
    max-width: 340px;
    font-size: 34px;
    text-align: left;
  }
`;

const Name = styled.span`
  font-family: "Neoneon";
  display: block;
  font-size: 60px;
  color: #df6e6e;
`;

const Col = styled.div`
  position: relative;
  flex: 1;
  &:nth-child(2) svg {
    height: 40vh;
    margin-bottom: 40px;
  }
`;

export const LandingPage = forwardRef((props, ref) => {
  return (
    <Bg ref={ref}>
      <Wrapper>
        <Col>
          <Headline1>
            Hi, I am <Name>PRADEEP</Name>
            <span>A full stack web developer</span>
          </Headline1>
        </Col>
        <Col>
          <Desk />
        </Col>
      </Wrapper>
    </Bg>
  );
});
