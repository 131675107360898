import { forwardRef } from "react";
import { Content, Para, SectionBg } from "../components/styles/common";
import { styled } from "styled-components";

const SectionBgStyled = styled(SectionBg)`
  &:before {
    background-color: #316b75;
  }
`;
export const About = forwardRef((props, ref) => {
  return (
    <SectionBgStyled ref={ref} id="about-id">
      <Content>
        <h2>About</h2>
        <Para>
          Hello, I'm a full stack developer with a flair for UX design, blending
          technical proficiency with a passion for creating engaging and
          intuitive digital experiences. With a solid foundation in frontend
          development languages and frameworks, coupled with a deep
          understanding of user behavior and design principles, I specialize in
          bridging the gap between form and function.
        </Para>
        <Para>
          My journey in the digital realm began with a fascination for crafting
          visually appealing interfaces that not only look great but also
          deliver seamless user interactions. Through my expertise in HTML, CSS,
          and JavaScript, I bring designs to life, ensuring they are not only
          aesthetically pleasing but also highly functional and responsive
          across various devices and platforms.
        </Para>
        <Para>
          What sets me apart is my ability to approach projects from both a
          developer's and a designer's perspective, allowing me to anticipate
          user needs and implement elegant solutions that prioritize usability
          and accessibility. Whether it's optimizing performance for faster load
          times or fine-tuning the user flow for maximum engagement, I thrive on
          the challenge of creating meaningful experiences that leave a lasting
          impression.
        </Para>
        <Para>
          Driven by a passion for continuous learning and innovation, I'm always
          exploring new technologies and design trends to stay ahead of the
          curve and deliver cutting-edge solutions that exceed expectations.
          With a commitment to excellence and a collaborative spirit, I'm ready
          to tackle any project and bring your vision to life in the digital
          world.
        </Para>
      </Content>
    </SectionBgStyled>
  );
});
