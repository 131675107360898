import { styled } from "styled-components";
import { useState } from "react";

const QuickLinksBg = styled.div`
  box-shadow: 0px 87px 212.16px 8.84px rgba(0, 0, 0, 0.34);
  height: 40px;
  width: 120px;
  line-height: 40px;
  border-radius: 0;
  cursor: pointer;
  display: block;
  font-weight: bold;
  ${(props) =>
    props.isActive
      ? `
    button {
      color: #3de4cf;
    }
  `
      : ``}
`;

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  top: auto;
  border-radius: 10px 10px 0 0;
  left: 50%;
  transform: translateX(-50%);
  right: auto;
  z-index: 100;
  display: flex;
  background: rgba(58, 178, 190, 0.9);
  max-width: 90vw;
  @media screen and (min-width: 1024px) {
    right: 20px;
    top: 0;
    left: auto;
    bottom: auto;
    transform: none;
    border-radius: 0 0 10px 10px;
    background: rgba(58, 178, 190, 0.6);
  }
`;

const Button = styled.button`
  padding: 0;
  border: none;
  background: none;
  color: #fff;
  cursor: pointer;
`;

export const QuickLinks = ({ elementsRef }) => {
  const [activeLink, setActiveLink] = useState();

  const scrollTo = (topPos, displayLink) => {
    window.scrollTo({
      top: topPos,
      left: 0,
      behavior: "smooth",
    });
    setActiveLink(displayLink);
  };
  let quicklinks = {
    home: {
      top: 0,
      display: "Home",
    },
    about: {
      top: 0,
      display: "About",
    },
    skills: {
      top: 0,
      display: "Skills",
    },
    experience: {
      top: 0,
      display: "Experience",
    },
  };
  if (Object.keys(elementsRef).length > 0) {
    quicklinks = {
      home: {
        ...quicklinks.home,
        top:
          elementsRef.landing.current.getBoundingClientRect().top +
          window.scrollY,
      },
      about: {
        ...quicklinks.about,
        top:
          elementsRef.about.current.getBoundingClientRect().top +
          window.scrollY,
      },
      skills: {
        ...quicklinks.skills,
        top:
          elementsRef.skills.current.getBoundingClientRect().top +
          window.scrollY,
      },
      experience: {
        ...quicklinks.experience,
        top:
          elementsRef.experience.current.getBoundingClientRect().top +
          window.scrollY,
      },
    };
  }

  return (
    <Wrapper>
      {Object.keys(quicklinks).map((link, index) => {
        return (
          <QuickLinksBg
            key={quicklinks[link].display}
            isActive={
              activeLink ? activeLink === quicklinks[link].display : index === 0
            }
            onClick={() =>
              scrollTo(quicklinks[link].top, quicklinks[link].display)
            }
          >
            <Button>{quicklinks[link].display}</Button>
          </QuickLinksBg>
        );
      })}
    </Wrapper>
  );
};
