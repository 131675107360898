import { Experience } from "./Experience";
import { LandingPage } from "./Landing";
import { Skills } from "./Skills";
import { About } from "./About";
import { useRef, useEffect } from "react";

export const AllSections = ({ setElementsRef }) => {
  const landingRef = useRef();
  const skillsRef = useRef();
  const experienceRef = useRef();
  const aboutRef = useRef();
  useEffect(() => {
    if ((landingRef, skillsRef, experienceRef, aboutRef, setElementsRef)) {
      setElementsRef({
        landing: landingRef,
        about: aboutRef,
        skills: skillsRef,
        experience: experienceRef,
      });
    }
  }, [aboutRef, landingRef, skillsRef, experienceRef, setElementsRef]);

  const resizeHandler = () => {
    if ((landingRef, skillsRef, experienceRef, aboutRef, setElementsRef)) {
      setElementsRef({
        landing: landingRef,
        about: aboutRef,
        skills: skillsRef,
        experience: experienceRef,
      });
    }
  };

  const debounceFunction = (cb, delay) => {
    let timer;
    return () => {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => cb(), delay);
    };
  };
  const debounce = debounceFunction(resizeHandler, 300);
  useEffect(() => {
    window.addEventListener("resize", debounce);
    return () => window.removeEventListener("resize", debounce);
  }, [debounce]);

  return (
    <>
      <LandingPage ref={landingRef} key="landing-page" />
      <About ref={aboutRef} key="about-page" />
      <Skills ref={skillsRef} key="skills-page" />
      <Experience ref={experienceRef} key="experience-page" />
    </>
  );
};
