import { styled } from "styled-components";
import skillsBg from "../../assets/background-1.jpg";

export const SectionBg = styled.div`
  position: relative;
  z-index: 1;
  min-height: 100vh;
  width: 100%;
  background-image: url(${skillsBg});
  background-size: cover;
  padding-bottom: 60px;
  &:before {
    content: "";
    opacity: 0.82;
    background: #434449;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Content = styled.div`
  position: relative;
  max-width: 1024px;
  margin: auto;
  h2 {
    font-family: "Library 3 am soft";
    font-size: 24px;
    padding: 80px 40px 40px;
    color: #cccccc;
  }
`;

export const Para = styled.p`
  text-align: left;
  font-size: 18px;
  line-height: 1.3;
  color: #e6e3e3;
  padding: 20px 40px 0;
`;
