import "./App.css";
import { QuickLinks } from "./components/QuickLinks";
import { AllSections } from "./pages/AllSections";
import { useState } from "react";

function App() {
  const [elementsRef, setElementsRef] = useState([]);
  return (
    <div className="App">
      <AllSections setElementsRef={setElementsRef} />
      <QuickLinks elementsRef={elementsRef} />
    </div>
  );
}

export default App;
