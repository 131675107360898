import { styled } from "styled-components";
import skillsBg from "../assets/background-1.jpg";
import { forwardRef } from "react";

const ExperienceBg = styled.div`
  position: relative;
  z-index: 1;
  min-height: 100vh;
  width: 100%;
  background-image: url(${skillsBg});
  &:before {
    content: "";
    opacity: 0.82;
    background: #434449;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

const Content = styled.div`
  position: relative;
  h2 {
    font-family: "Library 3 am soft";
    font-size: 24px;
    padding: 120px 40px 40px;
    color: #cccccc;
  }
`;

const DetailsWrapper = styled.div`
  margin: auto;
  color: #ffffff;
  @media screen and (min-width: 1024px) {
    width: 840px;
  }
`;

const ExpBlock = styled.div`
  h3 {
    font-size: 24px;
  }
  margin-bottom: 60px;
`;

export const Experience = forwardRef((props, ref) => {
  return (
    <ExperienceBg ref={ref}>
      <Content>
        <h2>Experience</h2>
        <DetailsWrapper>
          <ExpBlock>
            <h3>Vimeo Technologies</h3>
            <p>Duration: 8+ Years</p>
            <p>Frontend Developer</p>
            <p>Position : Engineering Manager</p>
          </ExpBlock>
          <ExpBlock>
            <h3>Heurion Consulting Pvt Ltd</h3>
            <p>Duration: 3+ Years</p>
            <p>UI/UX Designer / Frontend Developer</p>
            <p>Position : Software Engineer</p>
          </ExpBlock>
        </DetailsWrapper>
      </Content>
    </ExperienceBg>
  );
});
