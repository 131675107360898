import { useEffect, useState, forwardRef } from "react";
import { styled } from "styled-components";
import skillsBg from "../assets/background-1.jpg";
import { SkillBubble } from "../components/SkillBubble";

const GridContainer = styled.div`
  @media screen and (min-width: 1024px) {
    display: inline-grid;
    grid-template-columns: repeat(4, 1fr);
    > div {
      margin-left: 100%;
      place-self: center;
      &:nth-child(4) {
        margin-left: 0;
        grid-column-start: 4;
        grid-column-end: 6;
        ~ div {
          margin-left: 0;
        }
      }
      &:nth-child(9) ~ div {
        margin-left: 100%;
      }
    }
  }
`;
const Content = styled.div`
  position: relative;
  h2 {
    font-family: "Library 3 am soft";
    font-size: 24px;
    padding: 60px 40px 50px;
    color: #cccccc;
  }
`;

const SkillsBg = styled.div`
  position: relative;
  z-index: 1;
  min-height: 100vh;
  width: 100%;
  background-image: url(${skillsBg});
  &:before {
    content: "";
    opacity: 0.82;
    background: #535880;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

const GridOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const skillslist = [
  "HTML",
  "CSS",
  "JS",
  "ReactJS",
  "NextJS",
  "Redux",
  "nodeJS",
  "SQL",
  "Git",
  "Python",
  "Tableau",
  "Photoshop",
  "Sketch",
];

export const Skills = forwardRef((props, ref) => {
  const [activeIndex, setActiveIndex] = useState();
  useEffect(() => {
    let requestID = null;
    let start = Date.now();

    function playAnimation() {
      const interval = Date.now() - start;
      let randomnum = Math.floor(Math.random() * (13 - 1 + 1)) + 1;
      if (interval > 1000) {
        start = Date.now();
        setActiveIndex(randomnum);
      }

      requestID = requestAnimationFrame(playAnimation);
    }
    requestAnimationFrame(playAnimation);

    return () => {
      if (requestID) {
        cancelAnimationFrame(requestID);
      }
    };
  }, []);
  return (
    <SkillsBg ref={ref}>
      <Content>
        <h2>Skills</h2>
        <GridOuter>
          <GridContainer>
            {skillslist.map((skill, index) => (
              <SkillBubble name={skill} animate={activeIndex == index} />
            ))}
          </GridContainer>
        </GridOuter>
      </Content>
    </SkillsBg>
  );
});
